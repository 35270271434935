@media (max-width: 599px){html {
  --gap: 16px;
}

.swipe {
  padding-left: var(--padding-container);
  padding-right: var(--padding-container);
  scroll-padding-left: var(--padding-container);
  scroll-padding-right: var(--padding-container);
}

.form {
  padding: 16px;
}

:root {
  --default-font-size: 16px;
}

h1,
.h1 {
  font-size: 36px;
}

h2,
.h2 {
  margin-bottom: 30px;
  font-size: 24px;
}

h2 span,
.h2 span {
  font-size: 18px;
}

h3,
.h3 {
  font-size: 20px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {
  font-size: 16px;
}

h6,
.h6 {
  font-size: 16px;
}

.card-blog:not(.card-blog-first) {
  --paddingCard: 16px;
  --figureHeight: 130px;
}

.card-blog-first {
  --paddingCard: 24px;
  --figureHeight: 185px;
}}@media (max-width: 1099px){.btn-contact-us {
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  color: #fff;
  background-color: var(--blue-1);
}

.btn-contact-us:hover {
  background-color: var(--samsic-1);
}

.btn-list-of-post {
  display: none;
}

.single-services #breadcrumbs,
.single-services #breadcrumbs a,
.single-services #breadcrumbs .breadcrumb_last {
  color: #fff;
}

.single-services #breadcrumbs {
  margin-bottom: 16px;
}

.single-services .activities-title #breadcrumbs .breadcrumb_last,
.single-services .activities-title #breadcrumbs a {
  color: #fff;
}

.single-jobs #breadcrumbs {
  margin-bottom: 60px;
}

.bubble-contact {
  display: none;
}

.list-activities {
  margin: 30px auto;
}

.card-activity {
  height: 200px;
}

.card-excerpt {
  display: none;
}

.member {
  --figureSize: 100px;
  --figurePadding: 10px;
}

.name {
  --font-size: 16px;
}

.job {
  --font-size: 14px;
}

#open-countries-menu:checked~#all-countries-panel {
  left: 0;
}

#all-countries-panel {
  position: fixed;
  left: -100vw;
  top: 80px;
  bottom: 0;
  width: 100vw;
  min-height: calc(100vh - 80px);
  padding: 24px;
  background-color: #fff;
  transition: left .25s ease-in;
  overflow-y: scroll;
}

#all-countries-panel label {
  display: block;
  margin-bottom: 24px;
}

#all-countries-panel label .ico {
  float: right;
}

html:has(#open-primary-menu:checked) {
  overflow: hidden;
}

#primary-menu .n-1:not(.btn),
#primary-menu .n-2:not(.btn) {
  display: block;
  margin-bottom: 16px;
  font-size: 24px;
  color: var(--samsic-2);
}

#primary-menu .n-1:not(.btn) .ico,
#primary-menu .n-2:not(.btn) .ico {
  margin-top: 5px;
  font-size: 1.8rem;
  float: right;
}

#primary-menu .n-3:not(.btn) {
  display: block;
  padding: 8px 0;
  font-size: 14px;
  font-family: "Roboto",sans-serif;
}

#primary-menu .sub-menu {
  display: none;
}

#primary-menu .sub-menu .parent {
  position: relative;
  margin-left: 16px;
  margin-right: 16px;
}

#primary-menu .btn {
  margin-bottom: 16px;
}

#primary-menu input:checked~.sub-menu {
  display: block;
}

#primary-menu input:checked~.label-close {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
}

#primary-menu input:not(:checked)~label .ico {
  transform: rotate(-90deg);
}

.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#burger-menu {
  margin-right: -12px;
  padding: 10px;
  font-size: 26px;
  color: #fff;
}

.btn-search {
  position: fixed;
  top: 11px;
  right: 59px;
  font-size: 26px;
  padding: 10px;
}

.navigation-header {
  position: absolute;
  padding: 32px 20px;
  top: 80px;
  right: 0;
  left: -100vw;
  width: 100vw;
  min-height: calc(100vh - 80px);
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  transition: left .25s ease-in;
}

#open-primary-menu:checked~#burger-menu .ico[data-ico]::before {
  content: "cross";
}

#open-primary-menu:checked~.navigation-header {
  left: 0;
}

.navigation-languages {
  flex-direction: row-reverse;
}

.footer {
  padding-bottom: 60px;
  text-align: center;
}

.footer .logo {
  margin-top: -120px;
}

.footer-menus .footer-menu {
  margin-bottom: 60px;
}

#social-links li {
  margin: 0 16px;
}

.list-logos {
  justify-content: center;
}

.list-logos:not(:last-child) {
  margin-bottom: 60px;
}

.filters {
  margin-bottom: 30px;
}

.filters-archive {
  margin-bottom: 20px;
  padding: 40px 20px;
}

.filters-archive .switch-tags {
  margin-bottom: 16px;
}

.switch-categories {
  margin-bottom: 30px;
}

.banner-section .mce-content-body {
  margin-bottom: 20px;
}

.banner-section .btn:not(:last-child) {
  margin-right: 4px;
  margin-bottom: 4px;
}

.text_logos-section .grid,
.text_video-section .grid {
  border-radius: 8px;
}

.text_logos-section .list-logos,
.text_video-section .list-logos {
  grid-template-columns: repeat(2, auto);
}

.customers-list {
  margin-left: -20px;
  margin-right: -20px;
  grid-auto-columns: 100px;
}

.customers-list img {
  max-height: 60px;
}

.form-section figure {
  display: none;
}

.hero-section .container {
  height: calc(100vh - 80px);
  padding-bottom: 50px;
}

.hero-section .container.middle {
  height: auto;
}

.keys_datas-section li {
  padding: 10px 0 30px;
}

.keys_datas-section li:not(:last-child):after {
  bottom: 0;
  left: calc(50% - 30px);
  width: 60px;
  height: 1px;
}

.keys_datas-section .number {
  font-size: 40px;
}

.keys_datas-section .data {
  font-size: 24px;
}

.text_image-section figure {
  margin: auto;
}

.text_image-section figure img {
  max-height: 70vw;
}

.text_image-section .text_image {
  display: flex;
  flex-wrap: wrap-reverse;
}

.text_image_gradient-section figure {
  margin: auto;
  max-width: 480px;
}

.text_image_gradient-section img {
  max-height: 70vw;
}

.text_image_gradient-section p {
  font-size: 18px;
}

.bg-gradient-blue {
  background-image: linear-gradient(var(--blue-5) 40%, transparent);
}

.bg-gradient-beige {
  background-image: linear-gradient(var(--beige-4) 40%, transparent);
}

.bg-gradient-grey {
  background-image: linear-gradient(var(--beige-5) 40%, transparent);
}

.bg-gradient-white {
  background-image: linear-gradient(#FFFFFF 40%, transparent);
}

.two_image_text-section .content {
  margin: 0 -20px -110px;
  padding: 50px 20px 100px;
  grid-row-start: 1;
}

.two_image_text-section .images {
  z-index: 1;
  grid-row-start: 2;
}

.two_image_text-section .image-1 {
  border: solid 4px #fff;
  border-radius: 20px;
}

.two_image_text-section .image-1 img {
  max-height: 70vw;
}

.two_image_text-section .image-2 {
  display: none;
}

.text_testimony-section .btns-list-of-testis {
  display: none;
}

.input-tab-1:checked~.tab-1.text_image {
  display: flex;
  flex-wrap: wrap-reverse;
}

.input-tab-2:checked~.tab-2.text_image {
  display: flex;
  flex-wrap: wrap-reverse;
}

.input-tab-3:checked~.tab-3.text_image {
  display: flex;
  flex-wrap: wrap-reverse;
}

.video-section .container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.text_simple-section,
.text_logos-section,
.text_video-section,
.three_card_content-section,
.three_card_content_images-section,
.images_mosaic-section,
.images_slider-section,
.tabs-section,
.banner-section .display-bloc,
.download_document-section .display-bloc,
.pdf_list-section,
.form-section,
.faq-section,
.text_image-section .bg-partial-white,
.download_document_details-section {
  margin-top: 50px;
  margin-bottom: 50px;
}

.text_simple-section:last-child,
.text_logos-section:last-child,
.text_video-section:last-child,
.three_card_content-section:last-child,
.three_card_content_images-section:last-child,
.images_mosaic-section:last-child,
.images_slider-section:last-child,
.tabs-section:last-child,
.download_document-section:last-child,
.pdf_list-section:last-child,
.form-section:last-child,
.faq-section:last-child {
  margin-bottom: 70px;
}

.banner-section:last-child .display-bloc,
.download_document_details-section:last-child .display-bloc,
.text_image-section:last-child .bg-partial-white {
  margin-bottom: 70px;
}

.two_image_text-section {
  margin-bottom: 50px;
}

.text_image-section .container,
.text_testimony-section [class*=bg-],
.customers_list-section [class*=bg-],
.text_image_gradient-section .container,
.banner-section .display-full .container,
.keys_datas-section .container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.download_document_details-section .display-bloc,
.banner-section .display-bloc,
.text_logos-section .grid,
.text_video-section .grid {
  padding: 30px;
}

.text_image-section:last-child .container,
.text_testimony-section:last-child [class*=bg-],
.customers_list-section:last-child [class*=bg-],
.text_image_gradient-section:last-child .container,
.banner-section:last-child .display-bloc,
.download_document_details-section:last-child .display-bloc,
.banner-section:last-child .display-full .container,
.keys_datas-section:last-child .container {
  padding-bottom: 70px;
}

.text_image-section .bg-partial-white {
  padding-top: 0;
  padding-bottom: 0;
}

.text_image-section [class*=bg-partial].delete-margin-top {
  margin-top: 0;
  padding-top: 0;
}

.text_image-section [class*=bg-partial].delete-margin-bottom {
  margin-bottom: 0;
  padding-bottom: 0;
}

.breadcrumb_title-section,
.spacer-section {
  padding-top: 50px;
}

.single-content h1,
.single-content figure,
.single-content .go-all-post {
  margin-bottom: 30px;
}

.single-content .post-content {
  margin-bottom: 60px;
}

.single-content img {
  max-height: 300px;
}

.similar-news {
  margin-bottom: 60px;
}

.introduction {
  font-size: 18px;
}

.results-pagination {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 30px;
  text-align: center;
}

.share-post a,
.share-post button {
  margin-right: 24px;
}

.change-selection {
  text-align: center;
}

.change-selection span {
  display: block;
  margin-bottom: 20px;
}

.activities-thumbnail {
  position: relative;
  height: 340px;
  z-index: -1;
}

.activities-thumbnail:after {
  content: "";
  position: absolute;
  top: 40%;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(transparent 0%, #202020 60%);
  opacity: .9;
}

.activities-title {
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-top: -340px;
  padding-bottom: 32px;
  height: 340px;
}

.activities-title h1 {
  color: #fff;
  margin-bottom: 0;
}

.activities-content {
  padding-top: 20px;
  padding-bottom: 20px;
}

.archive-description {
  font-size: 18px;
}

.list-of-expertises {
  margin-left: var(--padding-container-reverse);
  margin-right: var(--padding-container-reverse);
}

.header-agency figure {
  max-height: 350px;
}

.members-section {
  padding-top: 50px;
  padding-bottom: 50px;
}

#map {
  width: 100%;
  height: 115vw;
}

.job-header,
.job-resume {
  margin-bottom: 30px;
}

.job-resume {
  font-size: 18px;
}

.job-form {
  margin: 30px auto;
}}@media (max-width: 799px){.form-tab {
  display: none;
  left: 0;
  right: 0;
  z-index: 10;
}

.label-form-tab {
  bottom: 2rem;
  right: 2rem;
  padding: .8rem 1.6rem;
  border-radius: 20px;
}

.label-form-tab-close {
  right: 2rem;
  top: 2rem;
}

#open-form-tab:checked~.form-tab {
  display: block;
}}